import Vue from 'vue';
import VueResource from 'vue-resource';
import Carousel from '../vue/carousel';
import Swiper, { Pagination, Navigation } from 'swiper';

let carousel = document.querySelector('#carousel');

Vue.use(VueResource);

if(carousel != null) {
    let app = new Vue({
        el: '#carousel',
        delimiters: ["${","}"],
        components: {
            Carousel
        },
        data: {
            categories: []
        },
        created: function() {
            let categories = this.$http.get('/api/v1/categories/type.json').then(function(data) {
                this.categories = data.body.data;
            });

            Promise.all([categories]).then(() => {
                //Explore swiper
                const exploreSwiper = document.getElementById('explore-swiper');

                if(exploreSwiper){

                    const sliderBreakpoint = window.matchMedia( '(min-width:1024px)' );
                    let exploreSwiper;

                    const breakpointChecker = function() {

                    // if larger viewport and multi-row layout needed
                    if ( sliderBreakpoint.matches === true ) {
                        if ( exploreSwiper !== undefined ) exploreSwiper.destroy( true, true );
                            return;
                        } else if ( sliderBreakpoint.matches === false ) {
                            return enableSwiper();
                        }
                    };

                    const enableSwiper = function() {
                        exploreSwiper = new Swiper ('#explore-swiper', {
                            grabCursor: true,
                            freeMode: true,
                            slidesPerView: 'auto',
                        });
                    };

                    //keep an eye on viewport size changes
                    sliderBreakpoint.addListener(breakpointChecker);
                    breakpointChecker();
                }
            })
        }
     });
}


    