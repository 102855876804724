<template>
    <div>
        <div class="column text-center">
            <h2 class="h2 uppercase text-text">{{title}}</h2>

            <ul class="tabs flex justify-center items-center">
                <li v-on:click="changeOption($event)" v-for="(option, index) in options" :key="option.uid"><a class="uppercase" :class="{ 'active': index === 0 }"  href="javascript:;">{{option.title}}</a></li>
            </ul>
        </div>
        <div id="explore-swiper" class="swiper-container my-8 lg:mt-12 xl:px-16">
            <!-- product module 1 -->
            <transition-group name="items" tag='div' class="explore-section__wrap swiper-wrapper lg:flex lg:justify-center">
                <div v-for="item in filteredcategories" :key="item.uid" class="swiper-slide product-module item">
                    <a class="relative group block py-6 transition text-center" :href="item.url">
                        <div class="product-img-wrap">
                            <img loading="lazy" class="lazy loaded product-img mx-auto object-cover mb-4 relative z-10 lg:mb-6" :src="item.image.url" :alt="item.image.title">
                        </div>
                        <h4 class="h4 uppercase">{{item.title}}</h4>
                    </a>
                </div>
            </transition-group>   
            <!-- end product module -->
        </div>

        <div v-if="link != null" class="row lg:mt-12">
            <div class="column text-center">
                <div class="inline-block"><a :href="link" class="button primary">{{linkText}} <i class="fas fa-chevron-right ml-2"></i></a></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            required: true
        },
        categories: {
            default: []
        },
        link: "",
        linkText: "",
        title: ""
    },
    methods: {
        changeOption(e) {
            this.selectedOption = e.target.innerHTML;
        }
    },
    computed: {
        filteredcategories() {
            let tempCat = this.categories.filter(item => item.categoryType == this.selectedOption);
            //Limit to 6
            if(tempCat.length > 6) {
                return tempCat.slice(0,6);
            } else {
                return tempCat;
            }
        }
    },
    data: function() {
        return {
            selectedOption: this.options[0].title
        }
    }
}
</script>

<style scoped>

    .items-enter {
        transform: scale(0.2);
        opacity:0;
    }

    .items-leave-to{
        opacity:0;
        transform: translateX(30px);
    }

    .items-leave-active {
        position: absolute;
    }
    .item {
        transition: all .3s ease-in-out
    }
</style>